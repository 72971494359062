.Footer {
    background-color: #4E4E4E;
    color: #fff;
    padding: 30px 10px;
  }
  
  .FooterContent {
    display: flex;
  }
  .PartA {
    width: 50%;
    text-align: center;
  }
  
  .LogoFooter {
    margin: auto;
}
.bannerLogo {
  height: 10svh;
   margin: 0 auto;
  padding-bottom: 30px;
}
.awardLogo {
  height: 18vh;
}
  .PartA p {
    font-size: 12px;
    margin: 0 auto;
    line-height: 1.5;
    width: 60%;
  }
  
  .VerticalLine {
    width: 3px;
    height: 140px;
    background-color: #fff;
    margin: 0 40px;
  }
  
  .PartB {
    width: 50%;
    display: flex;
  }
  .ContactContent, .FollowContent {
    width: 50%;
    margin: 0 40px;
  }
  
  .ContactContent h3, .FollowContent h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .ContactContent a {
    text-decoration: none;
    color:rgb(141, 190, 233);
  }
  .ContactContent p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .IconContent {
    display: flex;
  }
  .FollowIcon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  
  .HorizontalLn {
    width: 90%;
    height: 3px;
    background-color: #fff;
    margin: 20px auto;
  }
  
  .Copyright p {
    font-size: 14px;
    text-align: center;
  }
  
  @media screen and (max-width:440px){
    .Footer{
      padding: 10px 0;
    }
    .FooterContent {
      display: flex;
      flex-wrap: wrap;
      
    }
    .LogoFooter {
      width: 100%;
      margin: 0;
      padding: 10px 0;
    }
    .VerticalLine{
      width: 80%;
      height: 2px;
      margin: 10px auto;
    }
    .PartA {
      width: 100%;
    }
    .PartA p {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 30px;
      font-size: 14px;
    }
    .PartB{
      width: 100%;
      text-align: center;
      display:block;
    }
    .ContactContent{
      width: 100%;
      margin: 0;
    }
  
    .ContactContent p {
      font-size: 14px;
    }
    .FollowContent{
      width: 100%;
      margin: 0;
    }
    .ContactContent h3, .FollowContent h3 {
      font-size: 1.5em;
      margin: 20px;
    }
    .IconContent {
      width: 100%;
      justify-content: center;
      margin: 0 auto;
    }
    .Copyright p {
      font-size: 10px;
      text-align: center;
    }
    .Footer{
      padding-bottom: 10px;
    }
    .HorizontalLn {
      width: 90%;
      height: 1px;
      margin: 10px auto;
    }
  }