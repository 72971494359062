.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #d6d6d6;
  }
  
  .notFound h1 {
    font-size: 8rem;
    margin-bottom: 0;
    color: #777;
  }
  
  .notFound p {
    font-size: 1.5rem;
    color: #777;
  }

  
  .backBtn {
    background-color: #0077cc;
    border: none;
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 100px;
  }
  
  .backBtn:hover {
    background-color: #005fa3;
  }
  