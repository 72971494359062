.Content1 {
    background-image: url('../imgs/Gallery/banner.jpg');
    position: relative;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Intro {
    color: #fff;
    height: 15vh;
    font-size: 18px;
    text-transform:uppercase ;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px 0;
    padding: 0 20px;
    border-radius: 25px;
  }
  
  
  .navigationMenu {
    margin-top: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  
  .navigationMenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navigationMenu ul li {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .navigationMenu ul li:hover {
    color: #E08181;
  }
  .navigationMenu ul li.active {
    color: #E08181;
  }
  .ImgGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 40px 40px;
  }
  
  .ImgGrid img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .enlargedImgContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }
  
  .enlargedImg {
    max-height: 90vh;
    max-width: 90vw;
  }

  @media screen and (max-width: 440px) {
    .Content1 {
    height: 50vh;
    background-size: cover;
    background-position: center;
    }
    
    .Intro {
    height: 10vh;
    font-size: 14px;
    margin: 20px 0;
    padding: 0 10px;
    border-radius: 15px;
    }
    .navigationMenu {
      background-color: #333;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
          
    }
    
    .navigationMenu ul {
      display: flex;
      flex-wrap:wrap;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    
    .navigationMenu ul li {
      margin: 10px auto;
      padding: 0 5px;
      color: #fff;
    }
    
    .navigationMenu ul li a {
      display: block;
      padding: 10px;
      color: #fff;
      text-decoration: none;
    }
    
    .navigationMenu ul li a:hover {
      background-color: #555;
    }
    
    .ImgGrid {
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 20px;
    }
    
    .ImgGrid img {
    min-height: 200px;
    }
    
    .enlargedImg {
    max-height: 80vh;
    max-width: 80vw;
    }
    }