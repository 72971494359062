/******************************** Content 1 Start HERE ***************************************/
.Content1 {
    background-image: url('../imgs/Contact/contactUsBanner.jpg');
    position: relative;
    height: 60vh;
    background-size:cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Content1::before {
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Change the alpha value to adjust the darkness */
  z-index: 0;
}
.Content1Intro {
    text-align: center;
    width: 50%;
    color: white;
    z-index: 1;
}
.Content1Intro h1 {
    text-transform: uppercase;
    margin: 20px;
    font-size: 3em;
}
.Content1Intro p {
    font-size: 16px;
    line-height: 1.8em;
}
@media screen and (max-width: 440px) {
  .Content1 {
  height: 50vh;
  }
  .Content1Intro {
  width: 90%;
  }
  .Content1Intro h1 {
  font-size: 2em;
  }
  .Content1Intro p {
  font-size: 14px;
  }
  }
/******************************** Content 2 Start HERE ***************************************/
.Content2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .PartA {
    width: 50%;
    height:50vh;
    background-color: #E08181 ;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: white;
  }
  
  .PartAHeading {
    text-align: center;
    padding-top:5%;
  }
  
  .PartAHeading h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .PartAHeading .brLine {
    width:18%;
    height: 2px;
    background-color: white;
    margin: 1rem auto;
  }
  
  .PartAHeading p {
    font-size: 1.2rem;
    line-height: 1.5;
    width: 60%;
    margin: 0 auto;
  }
  
  .PartAHours {
    margin-top: 2rem;
    text-align: center;
  }
  
  .PartAHours h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .PartAHours p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .PartAButtons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    
  }
  
  .PartAButtons > a {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: transparent;
    border: 4px solid white;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    margin: 0 1rem;
    transition: all 0.3s ease;
  }
  
  .PartAButtons a:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #5e5e5e;
  }
  
  .PartB {
    width: 50%;
    height: 50vh;
    background-color: #5e5e5e;
  }
  
  @media screen and (max-width:440px) {
    .Content2{
      height: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;  
    }
    .PartA {
      height: auto;
      padding-bottom: 30px;
      width: 100%;
    }
    .PartAHours p{
      margin: 0 auto;
    }
    .PartA p {
      width: 90%;
    }
    .PartB{
      width: 99.5%;
    }
  }
  /******************************** Content 3 Start HERE ***************************************/
  form {
    display: flex;
    flex-wrap: wrap;
  }
.SubmitForm {
  width: 100%;
}
  .VerticalBrk {
    width: 2px;
    background-color: #ccc;
    height: 60vh;
    margin: auto 0;
  }
  .CustomerDetails, .ProjectContainer {
    width: 49%;
    margin: 0 auto;
  }
  .CustomerDetails h2 {
    padding-top: 40px;
    padding-left: 40px;
    font-size: 2em;
    text-transform: uppercase;

  }
  .CustomerContainer{
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
  }
  .CustomerContainer > div{
    display: flex;
    flex-direction: column;
  }
  .ContainerFirstName, .ContainerLastName, 
  .ContainerPhone , .ContainerService {
    margin: 0 auto;
    width: 40%;
    padding: 20px;
  }
  .ContainerEmail, .ContainerAdditionalInfo {
    width: 90%;
    padding: 20px;
    margin: 0 auto;;
  }
  .FixedTexarea{ 
    resize: none;
    overflow: auto;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  textarea {
    margin-top: 10px;
  } 
  input {
    height: 20px;
    margin-top: 10px;
  }
  select {
    margin-top: 10px;
    height: 25px;
  }
  label {
    font-size: 18px;
    font-weight: 500;
  }
  .ProjectContainer h2 {
    padding-top: 40px;
    padding-left: 40px;
    font-size: 2em;
    text-transform: uppercase;
  }
  .ProjectDetails {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 30px 0 30px;
  }
  .ProjectDetails > div{
    display: flex;
    flex-direction: column;
  }
  .ContainerAddress{
    width: 90%;
    padding: 20px;
  }
  .ContainerCity, .ContainerZipCode{
    width: 25%;
    padding: 20px;
  }
  .ContainerState{
    width: 10%;
    padding: 20px;
  }

  
  .PhotoContainer {
    width: 800px;
    margin: 0 auto;
  }
  
  .PhotoContainer h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .PhotoGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
  input[type="file"] {
    display: none;
  }
  
  
  .PhotoBox {
    position: relative;
    border: 3px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    height:150px;
  }
  
  .PhotoBox label {
    display: block;
    font-size: 18px;
    color: #999999;
    text-align: center;
    padding: 60px 16px;
    height: 90px;
    cursor: pointer;
  }
  
  .PhotoPreview {
    position: relative;
    height: 150px;
  }
  
  .PhotoPreview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .PhotoPreview:hover .Overlay {
    opacity: 1;
  }
  
  .DeleteButton {
    font-size: 32px;
    color: #fff;
    cursor: pointer;
  }
  .SubmitButton {
   background-color: #E08181 ; /* red */
    border: none;
    color: white;
    padding: 20px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-left:4%;
    margin-bottom: 20px;
    border-radius: 15px;
  }
  
.Loading {
  position: relative;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
  @media screen and (max-width: 440px) {
    form {
    display: block;
    }
    .VerticalBrk {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    }
    .CustomerDetails h2, .ProjectContainer h2 {
    padding: 20px;
    font-size: 1.5em;
    }
    .CustomerDetails, .ProjectContainer {
    width: 100%;
    }
    .CustomerContainer > div {
    width: 100%;
    margin: 0 auto;
    }
    .ContainerFirstName, .ContainerLastName, .ContainerPhone, .ContainerService, .ContainerEmail, .ContainerAdditionalInfo {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    }
    .ContainerCity, .ContainerZipCode {
    width: 40%;
    padding: 20px;
    }
    .ContainerState {
    width: 30%;
    padding: 20px;
    }
    .PhotoContainer {
    width: 95%;
    margin: 0 auto;
    }
    .PhotoBox {
    width: 100%;
    margin-bottom: 20px;
    height: 200px;
    }
    .SubmitForm {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    }
    .PhotoPreview {
      width: 100%;
      height: 100%;
    }
    .SubmitButton {
    margin-left: 0;
    }
    }