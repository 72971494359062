.navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    background-color: transparent;
    font-family: Montserrat, sans-serif;
    color: white;
    transition: background-color 0.8s ease;
    height: 80px;
    z-index: 9;
  }
  
  .navbar-scrolled {
    background-color: #333;
  }
  
  .logoImg {
    padding-left: 40px;
    padding-top: 0;
    width: 50%;
    height: 60px;
  }
  
  .logoImg img {
    height: 80px;
    cursor: pointer;
    width: 40%;
  }
  
  .navbarItems {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin: 0 10%;
  }
  
  .navbarItems li {
    width: auto;
    padding: 20px;
    margin: 10px;
    text-align: left;
    list-style: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
  }
  
  .navbarItems li a {
    text-decoration: none;
    color: white;
  }
  
  .servicesOptions {
    margin-top: 20px;
    display: none;
    position: absolute;
    color: white;
    background-color: #333;
  }
  
  .servicesOptions li {
    padding: 15px 10px;
    margin: 0;
    font-weight: 500;
  }
  
  
  .navbarItems li:hover .servicesOptions {
    display: block;
    z-index: 1;
  }
  .hamburger {
    display: none;
    cursor: pointer;
  }
  
  .line {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  
  .navbarItems-expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #333;
    position: absolute;
    top: 20px;
    left: 0;
  }
  
  .navbarItems-expanded li {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  /* Media Query for Mobile */
  @media (max-width: 868px) {
    .navbarItems {
      display: none;
      margin: 0;
    }
  
    .logoImg img {
      height: 70px;
      cursor: pointer;
      width: auto;
      padding: 5px 0;
      position: relative;
      right: 30px;
    }
    
    .navbar-expanded {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; 
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #333;
      z-index: 10;
      overflow: hidden;
    }
    .navbarItems-expanded{
      top:0;
    }
     .navbar-expanded li {
      width: 30%;
      margin: 10px auto;
      text-align:left ;
      padding: 10px 0;
    }
    .navbar-expanded li a {
      font-size: 20px;
    }
  
    .navbar-expanded .servicesOptions {
      margin-top: 20px;
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      z-index: 11;
      animation-name: slideUp;
      animation-duration: 2s;
      animation-timing-function: ease-out;
    }
   
    @keyframes slideUp {
      0% {
        opacity: 0;
        transform: translate(-50%, 20px);
      }
      100% {
        opacity: 1;
        transform: translate(-50%, 0); 
      }
    }
     
    .servicesOptions li{
        margin: 0;
        width: 100%;
        padding:15px;
    }
    .servicesOptions li a{
    font-size: 14px;
    }
  
    .hamburger .line {
      width: 30px;
      height: 3px;
      margin: 5px;
      color: white;
      border-radius: 5px;
      z-index: 3;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      margin-right: 0;
      position: absolute;
      top: 20px;
      right: 10px;
    }
  
    .line {
      width: 100%;
      height: 2px;
      color: white;
    }
  
    .navbar-expanded .hamburger {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  
    .navbar-expanded .logoImg {
      display:none; 
    }
    
  
    .navbar-expanded .navbarItems {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  