*{
  font-family: Montserrat, sans-serif;
}
/******************************** Content 1 Start HERE ***************************************/
.Content1 {
    background-image: url('../imgs/homePage/Content1/background.jpg');
    position: relative;
    height: 85vh;
    background-size:cover;
    background-position: center ;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}
.Content1::before {
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change the alpha value to adjust the darkness */
  z-index: 0;
}
.introTitle {
    text-align: center;
    position: relative;
    background-color: rgba(24, 22, 22, .5);
    height: auto;
    color: white;
    width: 100%;
    margin: 10% auto 0 auto;
    border-radius: 50px;
    padding-bottom: 15px;
}
.introTitle h1 {
padding: 20px;
font-size: 2.5em;
text-transform: uppercase;
}
.introTitle p {
  margin: 0 auto;
  width: 80%;
    padding:0 50px;
    line-height: 1.8em;
}
.h1ToRed {
    color: red;
}
.buttonContainer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.Content1Btns {
    position: relative;
    padding: 15px 30px;
    margin: 50px;
    font-size: 1em;
    border: 4px solid #E08181;
    background-color:transparent;
    text-decoration: none;
    color: white;
    font-weight: 800;
    text-transform:uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
}
.Content1Btns:hover {
 transform: translateY(-5px);
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
 background-color: #5e5e5e;
}

@media screen and (max-width:440px){
  
.Content1{
  height: 90vh;
}
  .introTitle {
    top: 50px;
    width: 90%;
  }
  .introTitle h1{
    font-size:26px;
    text-align:inherit;
  }
  .introTitle p {
    display: none;
    width: 90%;
    padding: 0;
    margin: 0 auto;
    font-size: 18px;
  }
  .buttonContainer{
    width: 100%;
  }
  .Content1Btns{
    font-size: 16px;
    width: 40%;
    margin: 35px 10px 0 10px;
  }
  
}
/******************************** Content 2 Start HERE ***************************************/
.Content2 {
    background-color: #FFFFFF;
    height: 300px;
    display: flex;
    flex-direction:column;
    align-items: center;
}
.Content2 h2 {
    text-align: center;
    width: 70%;
    margin: 50px;
    font-size: 2.3em;
    text-transform: uppercase;
    color: #E08181;

}
.Content2 p {
    text-align: center;
    width: 60%;
    font-size: 1em;
    line-height: 2em; 
    font-weight: 600;
}

@media screen and (max-width:440px) {
  .Content2{
    height: auto;
    width: 100%;
    padding: 20px 0;
  }
  .Content2 h2 {
      font-size: 24px;
      width: 95%;
      margin: 25px 5px;
    }
    .Content2 p {
      width: 95%;
      font-size:15px;
      padding-bottom: 30px;
    }
  
}
/******************************** Content 3 Start HERE ***************************************/
.Content3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47vh;
    background-color: #b6b6b6;
  }
  
  .serviceA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
  }
  .serviceB {
    width: 50%;}
  
  .serviceAImg {
    height: 350px;
    width: 550px;
    border: 3px solid black;
  }
  
  .serviceAContent {
    width: 40%;
    padding: 0 20px;
    margin: 0 40px;
  }
  
  .serviceAContent h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #E44C4C;
  }
  
  .serviceAContent p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .Content3LearnBtn {
    background-color: #E08181;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    text-transform: none;
  }
  .Content3LearnBtn:hover { 
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #5e5e5e;
  }
  
  @media screen and (max-width:440px) {
    .Content3{
      width: 100%;
      height: auto;
    }
    .serviceA {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .serviceB {
      width: 100%;
      padding-top: 20px;
    }
    .serviceAContent{
      width: 100%;
    }
    .serviceAContent h3 {
      padding-top: 10px;
    }
    .serviceAImg {
      width: 90%;
      height: 30%;
      margin: 0 auto;
    }
   .Content3LearnBtn{
    margin-bottom: 20px;
   }
  }
  /******************************** Content4  Start HERE ***************************************/
  .Content4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47vh;
    background-color: white;
  }
  .serviceB {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
  }
  
  .serviceBImg {
    height: 350px;
    width: 550px;
    border: 3px solid black;
  }

  .serviceBContent {
    width: 40%;
    padding: 0 20px;
    margin: 0 40px;
  }
  
  .serviceBContent h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #E44C4C;
  }
  
  .serviceBContent p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  @media screen and (max-width:440px) {
    .Content4 {
      width: 100%;
      height: auto;
    }
    .serviceB{
      width: 100%;
      display: flex; 
       flex-direction: column-reverse;
    }
    .serviceBContent{
      padding: 0 20px;
      margin: 0 40px;
      width: 70%;

    }
    .serviceBContent h3{
      margin-top: 20px;
    }
    .imgContainer{
      width: 100%;
      text-align: center;
    }
    .serviceBImg{
      width: 90%;
      height: 30%;
    }
    
  }
   /******************************** Content5  Start HERE ***************************************/

   .Content5 {
    height: 45vh;
    background-color: #E08181;
    display: flex;
   }
   .locationInfo {
    padding-top: 50px;
    width: 50%;
    text-align: center;
   }
   .locationInfo h2 {
    font-size: 2.3em;
    text-transform: uppercase;
    color: white;
    margin-bottom: 10px;
   }
   .locationInfo p{
    text-align: center;
    font-size: 18px;
    width: 65%;
    margin: 0 auto;
    line-height: 1.8em;
    color: white;
   }
   .brLine {
    width: 20%;
    height: 2px;
    background-color: white;
    margin: 0 auto 40px auto;
   }
   .Content5Btns{
    margin-top: 8%;
    padding: 10px 30px;
    border: 5px solid white;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size: 1em;
    transition: all 0.3s ease;
   }
   .Content5Btns:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #5e5e5e;
  }
   .map{
    width: 50%;
    background-color: #b6b6b6;
   }

   @media screen and (max-width:440px) {

    .Content5 {
      display: flex; 
       flex-direction: column-reverse;
       height: auto;
    }
    .locationInfo {
      width: 100%;
    }
    .locationInfo h2 {
      font-size: 24px;
    }
    .brLine{
      margin-bottom: 10px;
    }
    .locationInfo p{
      width: 95%;
      margin: 0 auto;
    }
    .map{
      width: 100%;
      height: 400px;
    }
    .Content5Btns{
      margin-bottom: 20px;
    }
    
   }