.Content1 {
    position: relative;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner1{
    background-image: url('../imgs/concreteService/banner.jpg');
    background-size:cover;
    background-position: center -10px;
}
.banner2 {
    background-image: url('../imgs/paintServices/banner.jpg');
    background-size:cover;
    background-position: center 35%;
}
.banner3 {
    background-image: url('../imgs/deckServices/banner.jpg');
    background-size:cover;
    background-position: center 35%;
}
.banner4 {
  background-image: url('../imgs/roofingServices/banner.jpg');
  background-size:cover;
  background-position: center 35%;
}
.banner5 {
  background-image: url('../imgs/stonePatioServices/banner.jpg');
  background-size:cover;
  background-position: center ;
}
.banner6 {
  background-image: url('../imgs/blockServices/banner.jpg');
  background-size:cover;
  background-position: center 0px ;
}
.Content1::before {
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change the alpha value to adjust the darkness */
  z-index: 0;
}
.Content1A {
    text-align: left;
    width: 50%;
    color: white;
    z-index: 1;
}
.Content1A h1 {
    text-transform: uppercase;
    margin: 10px;
    font-size: 3.5em;
}
.Content1A button {
    margin-top: 50px;
    padding: 20px 40px;
    font-size: 1.6em;
    color:white;
    text-transform: uppercase;
    font-weight: 700;
    background-color: transparent;
    border: 4px solid #E08181;
    cursor: pointer;
    margin-right: 30px;
    }
.Content1A button:hover {
    color: #E08181;
}
@media screen and (max-width: 440px) {
  .Content1A {
    width: 100%;
  }
  .Content1A h1 {
    font-size: 2em;
    line-height: 1.5em;
  }
  .Content1A button {
    font-size: 1.2em;
    margin-left: 20px;
    margin-top: 30px;
  }
}


/***************** Content 2 Starts Here ***************************/
.Content2 {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Content2Intro {
    text-align: center;  
    width: 70%;
}
.Content2Intro p {
    line-height: 1.8em;
    font-size: 1.2em;
    font-weight: 500;
    color: white;
  }
@media screen and (max-width:440px) {
  .Content2Intro p {
    font-size: 16px;
  }
  .Content2 {
    height: auto;
    padding: 20px 0;
  }
  .Content2Intro{
    width: 95%;
  }
}
  /***************** Content 3 Starts Here ***************************/

  .Content3 {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-bottom:14px ;

  }
  .Sections {
    display: flex;
    width: 100%;
  }
  .PartA, .PartB{
    width:50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .PartAImg {
    height: 80%;
    width: 80%;
    position: relative;
    border: 2px solid black;
  }
  .PartAImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .PartBDesc {
    width: 85%;
  }
  .PartBDesc h3 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #E44C4C;
    text-transform: uppercase;
  }
  .PartBDesc p {
    line-height: 2.4em;
    padding-top: 15px;
    font-weight: 500;
  }
  @media screen and (max-width: 440px) {
    .Content3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .Sections {
      display: flex;
      flex-direction: column;
    }
    
    .PartA, .PartB{
      width: 100%;
    }
    
    .PartAImg {
      height: auto;
      width: 95%;
      margin: 20px 20px;
      border: none;
    }
    .PartAImg img {
      object-fit: contain;
    }
    
    .PartBDesc {
      width: 100%;
    }
    
    .PartBDesc h3 {
      font-size: 24px;
      margin: 15px 30px;
    }
    .PartBDesc p {
      width: 95%;
      padding: 0 10px;
      margin-bottom: 10px;
    }
  }

   /***************** Content 4 Starts Here ***************************/

   .Content4 {
    height: auto;
    background-color:#adadad;
    padding-bottom: 50px;
   }

.Content4Intro {
    width: 100%;
    height: 200px;
    text-align: center;
    justify-items: center;
    color: white;
}
.Content4Intro h2 {
    font-size: 2.5em;
    text-transform: uppercase;
    padding: 30px;
    font-weight: 800;
}
.Content4Intro p {
    width: 70%;
    margin: 0 auto;
    line-height: 1.8em;
    font-weight: 500;
}
.Content4Img {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-wrap: wrap;

}.ImgContainer {
    width: 100%;
    height: auto;
    float: left;
    display: flex;
    align-items: center;
  }
  
  .ImgBlock {
    width: 90%;
    height: 90%;
    float: right;
    margin: 0 auto;
  }
  
  .BeforeAfterContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .BeforeImage,
  .AfterImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-out;
    
    border-radius: 15px;
  }
  
.Slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  position: absolute;
  bottom: -30px;
}

.Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #000;
  position: relative;
  top: 0x;
  cursor: pointer;
}

.Slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #000;
  position: relative;
  top: -6px;
  cursor: pointer;
}

  /****************/


.ImgBlock:hover .Overlay::before {
    content: "Use slider to compare";
    font-size: 25px;
    font-weight: 700;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .ImgBlock:hover .Overlay {
    display: block;
  }
  
  .Overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
  }
  
  
.brkLine {
    width: 85%;
    height: 3px;
    background-color: black;
    margin: 0  auto;
}
.beforeAfterGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.beforeAfter {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 440px) {
  .Content4Intro {
    height: auto;
  }
  .Content4Intro h2 {
    width: 90%;
    font-size: 26px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .Content4Intro p {
    width: 90%;
    font-size: 18px;
  }
  .Content4Img {
    height: auto;
    display: block;
  }
  .ImgContainer {
    width: 100%;
    height: auto;
    float: none;
    display: block;
  }
  .ImgBlock {
    width: 100%;
    height: auto;
    float: none;
    margin: 0 auto;
  }
  .BeforeAfterContainer {
    height: 350px;
    width: 95%;
    margin: 20px auto;
  }
  .beforeAfterGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  
  .Slider {

    width: 80%;
    bottom: 30px;
    left: 10%;
  }
  .ImgName {display: none;
  }
}