.Content1 {
    background-image: url('../imgs/About/aboutUsBanner.jpg');
    position: relative;
    height: 50vh;
    background-size:cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Content1::before {
    content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Change the alpha value to adjust the darkness */
  z-index: 0;
}
.Content1Intro {
    text-align: left;
    width: 50%;
    color: white;
    z-index: 1;
}
.Content1Intro h1 {
    text-transform: uppercase;
    margin: 10px;
    font-size: 3em;
}

.h1BrkLine {
    width: 25%;
    height: 2px;
    background-color: #fff;
}
@media screen and (max-width: 440px) {
    .Content1 {
        height: 50vh;
    }
    .Content1Intro {
        text-align: center;
        width: 100%;
        margin-bottom: 0px;
    }
    .Content1Intro h1 {
        font-size: 32px
    }
    .h1BrkLine {
        display: block;
        margin: 0 auto;
    }
}


/******************* Content2 Starts HERE *************************************/

.Content2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70Vh;

}

.Content2PartA {
    width: 60%;
}
.PartAInfo {
    width: 65%; /* reduce the width to make space for the floated element */
    float: right; /* float the element to the right */
    padding-right: 8%;
}

.PartAInfo h2{
    font-size: 28px;
    padding: 40px 0 ;
    color: #E08181;
}
.PartAInfo p{
    font-size: 20px;
    line-height: 2em;
}

.Content2PartB{
    padding: 30px;
    width: 40%;
}
.Content2PartB img {
    height: 550px;
    width: auto;
}
@media screen and (max-width: 440px) {
    .Content2 {
        height: auto;
        display: flex;
        flex-wrap: wrap;
    }   
    .Content2PartA {
    width: 100%;
    }
    .PartAInfo {
    width: 100%; /* reduce the width to make space for the floated element */
     /* float the element to the right */
    padding-right: 0;
    }
    .PartAInfo h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 25px;
    color: #E08181;
    }
    .PartAInfo p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    width: 90%;
    margin: 0 auto;
    }
    .Content2PartB {
    padding: 30px;
    width: 100%;
    }
    .Content2PartB img {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    }
    }

/******************* Content3 Starts HERE *************************************/

.Content3 {
    height: 35vh;
    background-color: #E08181;
    text-align:center;
    justify-items: center;
    justify-content: center;

}
.Content3PartA{
    width:80%;
    margin: 0 auto;
}
.Content3PartA h2 {
    font-size:2.6em;
    padding-bottom: 30px;
    text-transform: uppercase;
    padding: 40px;
    color: white;
}
.Content3PartA p {
    line-height: 1.8em;
    color: white;
    font-size: 20px;

}
@media screen and (max-width: 440px) {
    .Content3 {
    height: auto;
    }
    .Content3PartA {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    }
    .Content3PartA h2 {
    font-size: 2em;
    padding: 20px;
    }
    .Content3PartA p {
    font-size: 16px;
    line-height: 1.5em;
    padding: 20px;
    text-align: left;
    }
    }


/******************* Content4 Starts HERE *************************************/

.Content4 {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Content4PartA{
    width: 50%;
    position: relative;
}
.img1 {
  position: absolute;
  top: -50px;
  left: 20%;
  z-index: 3;
  width: 70%;
  height: 300px;
  object-fit: cover;
  object-position:center -100px;
}

.img2 {
  position: absolute;
  bottom: 10px;
  left: 140px;
  z-index: 2;
  width: 30%;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.img3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 50%;
  height: 200px;
  object-fit: cover;
  object-position:center -10px;
}
@keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .img1, .img2, .img3 {
    animation: slideIn 1s ease-out;
  }
.Content4PartB{
    width: 50%;
}
.PartBInfo {
    width: 80%; /* reduce the width to make space for the floated element */
    float: left; /* float the element to the right */
    padding-left: 8%;
}
.PartBInfo h2{
    font-size: 28px;
    padding: 40px 0 ;
    color: #E08181;
    text-transform: uppercase;
}
.PartBInfo p{
    font-size: 20px;
    line-height: 2em;
}
@media screen and (max-width: 440px) {
    .Content4 {
      height: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      

    }
    
    .Content4PartA {
        overflow: hidden;
        position: relative;
        width: 80%;
        height: 300px;
        margin-top: 30px;
      }
    
      .Content4PartA img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        z-index: 1;
      }
    
      .Content4PartA img.active {
        opacity: 1;
        z-index: 2;
      }
    
      @keyframes slide {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    
      .Content4PartA img:not(.active) {
        animation-name: slide;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
    
    .img1 {
      position: static;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      margin-bottom: 20px;
    }
    
    .img2 {
      position: static;
      width: 70%;
      height: auto;
      object-fit: cover;
      margin-bottom: 20px;
      box-shadow: none;
      z-index: 1;
    }
    
    .img3 {
      position: static;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
    
    .Content4PartB{
      width: 100%;
      margin-bottom: 20px;
    }
    
    .PartBInfo {
      width: 90%;
      float: none;
      padding: 0;
      margin: 0 auto;
    }
    
    .PartBInfo h2{
      font-size: 24px;
      padding: 20px 0;
      text-align: center;
    }
    
    .PartBInfo p{
      font-size: 16px;
      line-height: 1.8em;

    }
  }
  